import InfoWidget from "./infoWidget";
import Typography from "@material-ui/core/Typography";
import React, {useContext, useEffect, useState} from "react";
import {Build} from "@material-ui/icons";
import {userContext} from "../login/userContext";
import statisticService from "../service/statisticsService";


function TaskWidget() {
    const [tasksCount, setTasksCount] = useState(0)
    const user = useContext(userContext)

    useEffect(() => {
        statisticService.fetchTasks(user.auth, user.user.clientId, (data) => {
            setTasksCount(data.count)
        })
    },[user])
    return (

        <InfoWidget title={'Anzahl an Tätigkeiten'} avatar={<Build />}>
            <Typography variant={"h4"} align={"center"}>
                {tasksCount.toLocaleString()}
            </Typography>
        </InfoWidget>
    )
}


export default TaskWidget