import InfoWidget from "./infoWidget";
import {Image} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React, {useContext, useEffect, useState} from "react";
import {userContext} from "../login/userContext";
import statisticService from "../service/statisticsService";

function ImageWidget() {
    const [imageCount, setImageCount] = useState(0)
    const user = useContext(userContext)

    useEffect(() => {
        statisticService.fetchImages(user.auth, (data) => {
            setImageCount(data.length)
        })
    },[user])
    return (

        <InfoWidget title={'Anzahl an Fotos'} avatar={<Image />}>
            <Typography variant={"h4"} align={"center"}>
                {imageCount.toLocaleString()}
            </Typography>
        </InfoWidget>
    )
}


export default ImageWidget