import InfoWidget from "./infoWidget";
import {Warning} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React, {useContext, useEffect, useState} from "react";
import {userContext} from "../login/userContext";
import statisticService from "../service/statisticsService";

function DefectWidget() {
    const [defectsCount, setDefectsCount] = useState(0)
    const user = useContext(userContext)

    useEffect(() => {
        statisticService.fetchDefects(user.auth, user.user.clientId, (data) => {
            setDefectsCount(data.count)
        })
    },[user])
    return (

        <InfoWidget title={'Anzahl an Mängeln'} avatar={<Warning />}>
            <Typography variant={"h4"} align={"center"}>
                {defectsCount.toLocaleString()}
            </Typography>
        </InfoWidget>
    )
}


export default DefectWidget