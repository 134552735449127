import React, {useEffect, useState} from 'react';
import './App.css';
import Dashboard from "./dashboard/dashboard";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import TopBar from "./topbar/topbar";
import SideMenu from "./menu/sideMenu";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {green, grey} from "@material-ui/core/colors";
import Login from "./login/login";
import Contract from "./contract/contract";
import {userContext} from './login/userContext';
import UserList from "./user/userList";
import CreateUser from "./user/createUser";

function App() {
    const [showSideMenu, setShowSideMenu] = useState(false)
    const [user, setUser] = useState(undefined)

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: green['600']
            },
            secondary: {
                main: grey[600]
            },
        }
    });

    useEffect(() => {
        let userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
            setUser(userData)
        }
    }, [])

    const toggleSideMenu = () => {
        setShowSideMenu(!showSideMenu)
    }

    const onLoggedIn = (user) => {
        setUser(user)
        sessionStorage.setItem("user", JSON.stringify(user))
    }

    const onLogout = () => {
        setUser(undefined)
        sessionStorage.removeItem("user")
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <userContext.Provider value={user}>
                {!user && <Login loginSuccessful={onLoggedIn}/>}

                {user &&
                <Router>
                    <TopBar toggleMenuCallback={toggleSideMenu} onLogout={onLogout}/>
                    <SideMenu show={showSideMenu}></SideMenu>

                    <Switch>
                        <Route path="/contract">
                            <Contract/>
                        </Route>
                        <Route path="/users/create">
                            <CreateUser/>
                        </Route>
                        <Route path="/users/:editUserId">
                            <CreateUser/>
                        </Route>
                        <Route path="/users">
                            <UserList/>
                        </Route>
                        <Route path="/">
                            <Dashboard/>
                        </Route>
                    </Switch>
                </Router>
                }
                </userContext.Provider>
            </ThemeProvider>
        </div>
    );
}

export default App;
