class ContractService {

    fetchContractInfos(auth, onFetch) {
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/inframanager/contract`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`
            }
        }).then((response) => {
            if(response.ok){
                response.json().then( data =>{
                    onFetch(data)
                })
            }
        })
    }
}

const contractService = new ContractService();
export default contractService