import React, {useContext, useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {CollectionsBookmark, EuroSymbol, List, People} from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import {userContext} from "../login/userContext";
import contractService from "../service/contractService";
import Link from "@material-ui/core/Link";

function ContractInfo({title, avatar, children}) {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,

        },
    }));

    const classes = useStyles()
    return (
        <Card className={classes.paper}>
            <CardHeader title={
                <Typography variant={"h6"}>
                    {title}
                </Typography>}
                        avatar={
                            <Avatar>
                                {avatar}
                            </Avatar>
                        }>
            </CardHeader>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}

function Contract() {
    const dummyContract = {
        id: 0,
        entityCreatedTstamp: 0,
        clientId: 0,
        type: "",
        contractStartTstamp: 0,
        supportType: "",
        amountOfUsers: 0,
        amountOfInfraObjects: 0,
        duration: "",
        automaticRenewal: false,
        paymentType: "",
        paymentFrequency: ""
    }
    const [contract, setContract] = useState(dummyContract)
    const user = useContext(userContext)

    useEffect(() => {
        contractService.fetchContractInfos(user.auth, (data) => {
            setContract(data)
        })
    },[user])
    return (<div>
        <Typography variant={"h4"} align={"center"}>
            Vertragsdaten
        </Typography>
        <Grid container direction={"row"} justify={"center"}>
            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <ContractInfo title={"Anzahl an Objekten"} avatar={<CollectionsBookmark/>}>
                            <Typography variant={"h4"} align={"center"}>
                                {contract.amountOfInfraObjects.toLocaleString()}
                            </Typography>
                        </ContractInfo>
                    </Grid>
                    <Grid item xs={6}>
                        <ContractInfo title={"Anzahl an Benutzern"} avatar={<People/>}>
                            <Typography variant={"h4"} align={"center"}>
                                {contract.amountOfUsers.toLocaleString()}
                            </Typography>
                        </ContractInfo>
                    </Grid>
                    <Grid item xs={12}>
                        <ContractInfo title={"Vertragsdetails"} avatar={<List/>}>
                            <div align={"center"}>
                                <Grid container spacing={3} alignItems={"flex-start"}>
                                    <Grid item xs={6}>
                                        <Box fontWeight="fontWeightBold">Typ:</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {contract.type}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box fontWeight="fontWeightBold">Vertragslaufzeit:</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {contract.duration}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box fontWeight="fontWeightBold">Automatische Verlängerung:</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {contract.automaticRenewal && "Aktiviert"}
                                        {!contract.automaticRenewal && "Deaktiviert"}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box fontWeight="fontWeightBold">Support:</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            contract.supportType === 'E-Mail' &&
                                            <Link href="mailto:support@gam-project.at">
                                                {contract.supportType}
                                            </Link>
                                        }
                                        {
                                            contract.supportType !== 'E-Mail' && contract.supportType
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </ContractInfo>
                    </Grid>
                    <Grid item xs={12}>
                        <ContractInfo title={"Abrechnungsdetails"} avatar={<EuroSymbol/>}>
                            <div align={"center"}>
                                <Grid container spacing={3} alignItems={"flex-start"}>
                                    <Grid item xs={6}>
                                        <Box fontWeight="fontWeightBold">Typ:</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {contract.paymentType}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box fontWeight="fontWeightBold">Modus:</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {contract.paymentFrequency}
                                    </Grid>
                                </Grid>
                            </div>
                        </ContractInfo>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


    </div>)
}

export default Contract

