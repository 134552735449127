import React, {useContext} from 'react';
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {Box, IconButton, Toolbar} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/core/styles';
import {AccountCircle, ExitToApp} from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {userContext} from "../login/userContext";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    title: {
        flexGrow: 1
    }
}));

function TopBar({toggleMenuCallback, onLogout}) {
    const classes = useStyles()
    const user = useContext(userContext)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const toggleMenu = () => {
        toggleMenuCallback()
    }


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div className={classes.root}>
        <AppBar position={"fixed"} className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu"
                            onClick={toggleMenu}>
                    <MenuIcon/>
                </IconButton>
                <Box className={classes.title}>
                <Typography variant="h6"  align={"center"}>
                    InfraManager - Control Center
                </Typography>
                <Typography variant="subtitle2" align={"center"}>
                    {user.user.clientName}
                </Typography>
                </Box>
                <IconButton color="inherit" onClick={handleMenu}>
                    <AccountCircle/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={onLogout}>
                        <ListItemIcon>
                            <ExitToApp/>
                        </ListItemIcon>
                        <ListItemText>
                            Abmelden
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
    </div>
}

export default TopBar