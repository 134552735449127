import React, {useContext, useEffect, useState} from 'react'
import {userContext} from "../login/userContext";
import InfoWidget from "./infoWidget";
import Typography from "@material-ui/core/Typography";
import {Place} from "@material-ui/icons";
import statisticService from "../service/statisticsService";

function InfraObjectWidget({maxInfraObjects}) {
    const [infraObjectCount, setInfraObjectCount] = useState(0)
    const user = useContext(userContext)
    useEffect(() => {
       statisticService.fetchInfraObjects(user.auth, user.user.clientId, (data) => {
           setInfraObjectCount(data.count)
       })
    },[user])


    return (
      <InfoWidget title={"Anzahl an Objekten"} avatar={<Place />}>
          <Typography variant={"h4"} align={"center"}>
              { `${infraObjectCount.toLocaleString()} / ${maxInfraObjects.toLocaleString()}`}
          </Typography>
      </InfoWidget>
    )
}

export default InfraObjectWidget