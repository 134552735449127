import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Divider from "@material-ui/core/Divider";

function InfoWidget({title, avatar, children}) {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,

        },
    }));

    const classes = useStyles()
    return (
        <Card className={classes.paper}>
            <CardHeader title={
                <Typography variant={"h6"}>
                    {title}
                </Typography>}
                        avatar={
                            <Avatar>
                                {avatar}
                            </Avatar>
                        }
            subheader={<Divider/>}>
            </CardHeader>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}

export default InfoWidget