import React from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

function ConfirmationDialog({selectedUserName, title, cancelText, confirmText, text, onCancel, onConfirm}) {

    const open = selectedUserName !== undefined

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {text}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    {cancelText}
                </Button>
                <Button
                    onClick={onConfirm}
                    color="primary">
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )


}

export default ConfirmationDialog