import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import UserWidget from "../widgets/userwidget";
import InfraObjectWidget from "../widgets/infraobjectwidget";
import TaskWidget from "../widgets/taskWidget";
import FileWidget from "../widgets/fileWidget";
import ImageWidget from "../widgets/imageWidget";
import DefectWidget from "../widgets/defectWidget";
import {userContext} from "../login/userContext";
import contractService from "../service/contractService";

function Dashboard() {
    const dummyContract = {
        id: 0,
        entityCreatedTstamp: 0,
        clientId: 0,
        type: "",
        contractStartTstamp: 0,
        supportType: "",
        amountOfUsers: 0,
        amountOfInfraObjects: 0
    }
    const [contract, setContract] = useState(dummyContract)
    const user = useContext(userContext)

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2)
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            height: '200px',
            width: '200px'
        },
    }));



    useEffect(() => {
        contractService.fetchContractInfos(user.auth, (data) => {
            setContract(data)
        })
    },[user])

    const classes = useStyles()
    return (
        <div className={classes.root}>
        <Grid container direction="row" spacing={3}>
            <Grid item xs={4}>
                <InfraObjectWidget maxInfraObjects={contract.amountOfInfraObjects}/>
            </Grid>
            <Grid item xs={4}>
                <UserWidget maxUsers={contract.amountOfUsers}/>
            </Grid>
            <Grid item xs={4}>
                <TaskWidget />
            </Grid>
            <Grid item xs={4}>
                <FileWidget/>
            </Grid>
            <Grid item xs={4}>
                <ImageWidget />
            </Grid>
            <Grid item xs={4}>
                <DefectWidget />
            </Grid>

        </Grid>
        </div>
    )
}

export default Dashboard
