import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";
import red from "@material-ui/core/colors/red";


function Login({children, loginSuccessful}) {
    const useStyles = makeStyles({
        root: {
            paddingTop: 100,
        },
    });
    const classes = useStyles();
    const {apiUrl} = window['runConfig']
    const [userName, setUserName] = useState(undefined)
    const [password, setPassword] = useState(undefined)

    const [loginFailed, setLoginFailed] = useState(false)


    const login = (event) => {
        const userData = new Buffer(`${userName}:${password}`).toString('base64');
        event.preventDefault();
        fetch(`${apiUrl}/inframanager/user/login`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Authorization': `Basic ${userData}`
            }
        }).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    if(data.roleNames.some( role => role==="ClientAdmin")) {
                        loginSuccessful({user: data, auth: userData})
                    }else {
                        setLoginFailed(true)
                        console.log('not enough permissions')
                    }
                })

            }
        })
    }

    return <div>
        <form onSubmit={login}>
            <Grid container justify={"center"} alignItems={"center"} className={classes.root}>
                <Card>
                    <CardMedia image={'/logo.png'} component={"img"}>

                    </CardMedia>
                    <CardContent>
                        <Grid container direction="column" spacing={3}>
                            <Grid item xs>
                                <TextField required
                                           label={'Benutzer'}
                                           onChange={(e) => {
                                               setUserName(e.target.value)
                                           }}>

                                </TextField>
                            </Grid>
                            <Grid item xs>
                                <TextField required
                                           label={'Passwort'}
                                           type={"password"}
                                           onChange={(e) => {
                                               setPassword(e.target.value)
                                           }}
                                >

                                </TextField>
                            </Grid>
                            <Grid item xs>
                                {
                                    loginFailed &&
                                    <Typography variant={"caption"} color={"error"}>Login fehlgeschlagen</Typography>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button variant="outlined" color="primary" style={{textTransform: "none"}}
                                disabled={!userName || !password}
                                type={"submit"}
                        >Login</Button>
                    </CardActions>

                </Card>
            </Grid>
        </form>
    </div>

}

export default Login