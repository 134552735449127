

class StatisticsService{


    fetchInfraObjects(auth, clientId, onFetch){
        this.fetchData(auth, clientId, "infraobjects", onFetch)
    }

    fetchTasks(auth, clientId, onFetch) {
        this.fetchData(auth, clientId, "tasks", onFetch)
    }

    fetchDefects(auth, clientId, onFetch) {
        this.fetchData(auth, clientId, "defects", onFetch)
    }

    fetchUsers(auth, clientId, onFetch) {
        this.fetchData(auth, clientId, "users", onFetch)
    }

    fetchFiles(auth,onFetch) {
        this.fetchFileData(auth, (data) => {
            onFetch(data.filter(f => f.contentType!== 'image/jpeg' && f.contentType!=='image/png'))
        })
    }

    fetchImages(auth,onFetch) {
        this.fetchFileData(auth, (data) => {
            onFetch(data.filter(f => f.contentType=== 'image/jpeg' || f.contentType==='image/png'))
        })
    }

    fetchFileData(auth, onFetch){
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/filecloud-server/rest/file/listall`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`
            }
        }).then((response) => {
            if(response.ok){
                response.json().then( data =>{
                    onFetch(data)
                })
            }
        })
    }

    fetchData(auth, clientId, type, onFetch){
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/inframanager/statistics/${clientId}/${type}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`
            }
        }).then((response) => {
            if(response.ok){
                response.json().then( data =>{
                    onFetch(data)
                })
            }
        })
    }
}

const statisticService = new StatisticsService()

export default statisticService;

