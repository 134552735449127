class UserService{

    fetchUsers(auth, onFetch){
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/inframanager/user`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`
            }
        }).then((response) => {
            if(response.ok){
                response.json().then( data =>{
                    onFetch(data)
                })
            }
        })
    }

    fetchCompanies(auth, onFetch){
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/inframanager/user/companies`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`
            }
        }).then((response) => {
            if(response.ok){
                response.json().then( data =>{
                    onFetch(data)
                })
            }
        })
    }

    createUser(auth, userData,onResponse){
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/inframanager/user/`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Authorization': `Basic ${auth}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            onResponse(response)
        })
    }

    editUser(auth, userData,onResponse){
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/inframanager/user/`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(userData),
            headers: {
                'Authorization': `Basic ${auth}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            onResponse(response)
        })
    }

    fetchUser(auth, userId,onResponse){
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/inframanager/user/${userId}/get`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if(response.ok){
                response.json().then( data =>{
                    onResponse(data)
                })
            }
        })
    }

    changePassword(auth, userName, newPassword,onResponse){
        const {apiUrl} = window['runConfig']
        fetch(`${apiUrl}/inframanager/user/${userName}/password`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify({newPassword: newPassword}),
            headers: {
                'Authorization': `Basic ${auth}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            onResponse(response)
        })
    }

    deactivateUser(auth, userName, onResponse) {
        const {apiUrl} = window['runConfig']
        const now = Date.now()
        fetch(`${apiUrl}/inframanager/api/v2/users/${userName}/deactivatedTimestamp`, {
            credentials: 'include',
            method: 'PUT',
            body: `${now}`,
            headers: {
                'Authorization': `Basic ${auth}`,
                'Content-Type': 'text/plain'
            }
        }).then((response) => {
            onResponse(response)
        })
    }
}

const userService = new UserService();
export default userService