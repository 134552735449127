import InfoWidget from "./infoWidget";
import {Folder} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React, {useContext, useEffect, useState} from "react";
import {userContext} from "../login/userContext";
import statisticService from "../service/statisticsService";

function FileWidget() {
    const [fileCount, setFileCount] = useState(0)
    const user = useContext(userContext)

    useEffect(() => {
        statisticService.fetchFiles(user.auth, (data) => {
            setFileCount(data.length)
        })
    },[user])
    return (

        <InfoWidget title={'Anzahl an Dateien'} avatar={<Folder />}>
            <Typography variant={"h4"} align={"center"}>
                {fileCount.toLocaleString()}
            </Typography>
        </InfoWidget>
    )
}


export default FileWidget