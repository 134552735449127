import React, {useContext, useEffect, useState} from 'react'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {makeStyles} from "@material-ui/core/styles";
import {userContext} from "../login/userContext";
import userService from "../service/userService";
import {useHistory, useParams} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    fullWidth: {
        width: "100%",
    },

    topMargin: {
        marginTop: theme.spacing(2)
    }
}));

function CreateUser() {
    const history = useHistory()
    const classes = useStyles();
    const [userName, setUserName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [mail, setMail] = useState("")
    const [companyId, setCompanyId] = useState('')
    const [departmentId, setDepartmentId] = useState('')
    const [password, setPassword] = useState("undefined")
    const [passwordRetype, setPasswordRetype] = useState(undefined)
    const [passwordError, setPasswordError] = useState(false)
    const [passwordRequirementError, setPasswordRequirementError] = useState(false)

    const [companies, setCompanies] = useState([])
    const [departments, setDepartments] = useState([])

    const user = useContext(userContext)

    const {editUserId} = useParams()

    useEffect(() => {
        userService.fetchCompanies(user.auth, (data) => {
            setCompanies(data)
        })
    }, [user])

    useEffect(() => {
        if (editUserId === undefined) {
            return
        }
        console.log(editUserId)
        userService.fetchUser(user.auth, editUserId, (data) => {
            console.log(data)
            setUserName(data.userId)
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setMail(data.mail)
            setCompanyId(data.companyId)
            setDepartmentId(data.departmentId)
        })
    }, [editUserId, user])

    useEffect(() => {

        if (companies.length <= 0 || companyId === '') {
            return
        }

        let foundCompany = companies.find(c => c.id === companyId)
        console.log(foundCompany)
        setDepartments(foundCompany.departments)

    }, [companies, companyId])

    useEffect(() => {
        if (!password || password.length === 0) {
            return
        }
        if(password.length < 8){
            setPasswordRequirementError(true)
        }else{
            setPasswordRequirementError(false)
        }
        setPasswordError(password !== passwordRetype)

    }, [password, passwordRetype])


    const createUser = (event) => {
        console.log('create user');
        console.log(userName)

        if(!editUserId) {
            userService.createUser(user.auth, {
                userId: userName,
                firstName: firstName,
                lastName: lastName,
                mail: mail,
                roleNames: ['User'],
                clientId: user.user.clientId,
                companyId: companyId,
                departmentId: departmentId,
                password: password
            }, (response) => {
                if (response.ok) {
                    history.push("/users")
                }
            })
        }else{
            userService.editUser(user.auth, {
                userId: userName,
                firstName: firstName,
                lastName: lastName,
                mail: mail,
                roleNames: ['User'],
                clientId: user.user.clientId,
                companyId: companyId,
                departmentId: departmentId,
            }, (response) => {
                if (response.ok) {
                    history.push("/users")
                }
            })
        }


        event.preventDefault();

    }

    return (
        <div>
            <form onSubmit={createUser}>
                <Grid container direction={"row"} justify={"center"} className={classes.topMargin}>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader title={
                                <Typography variant={"h6"} align={"center"}>
                                    Benutzer hinzufügen
                                </Typography>
                            }></CardHeader>
                            <CardContent>
                                <Grid container xs={12} spacing={3}>
                                    {!editUserId &&
                                    <Grid item xs={12} justify={"center"}>
                                        <TextField label={"Benutzername"} required={true} variant={"outlined"}
                                                   onChange={event => setUserName(event.target.value)}
                                                   className={classes.fullWidth} value={userName}/>
                                    </Grid>
                                    }
                                    <Grid item xs={6} justify={"center"}>
                                        <TextField label={"Vorname"} required={true} variant={"outlined"}
                                                   onChange={event => setFirstName(event.target.value)}
                                                   className={classes.fullWidth} value={firstName}/>
                                    </Grid>
                                    <Grid item xs={6} justify={"center"}>
                                        <TextField label={"Nachname"} required={true} variant={"outlined"}
                                                   onChange={event => setLastName(event.target.value)}
                                                   className={classes.fullWidth} value={lastName}/>
                                    </Grid>
                                    <Grid item xs={12} justify={"center"}>
                                        <TextField label={"E-Mail"} required={true} variant={"outlined"}
                                                   type={"email"}
                                                   onChange={event => setMail(event.target.value)}
                                                   className={classes.fullWidth} value={mail}/>
                                    </Grid>
                                    <Grid item xs={6} justify={"center"}>
                                        <FormControl variant="outlined" className={classes.formControl} required={true}
                                                     className={classes.fullWidth}>
                                            <InputLabel id="company">
                                                Unternehmen
                                            </InputLabel>
                                            <Select
                                                value={companyId}
                                                labelId={"company"}
                                                onChange={event => setCompanyId(event.target.value)}>
                                                {
                                                    companies.map(row => (
                                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                                        )
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} justify={"center"}>
                                        <FormControl variant="outlined" className={classes.formControl} required={true}
                                                     className={classes.fullWidth}>
                                            <InputLabel id="department">
                                                Abteilung
                                            </InputLabel>
                                            <Select
                                                value={departmentId}
                                                labelId={"department"}
                                                onChange={event => setDepartmentId(event.target.value)}
                                            >
                                                {
                                                    departments.map(row => (
                                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                                        )
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {!editUserId && (
                                        <>
                                            <Grid item xs={6} justify={"center"}>
                                                <TextField label={"Passwort"} required={true} variant={"outlined"}
                                                           type={"password"}
                                                           onChange={event => setPassword(event.target.value)}
                                                           className={classes.fullWidth}
                                                           error={passwordRequirementError}
                                                           helperText={passwordRequirementError && "Min. 8 Zeichen"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} justify={"center"}>
                                                <TextField label={"Passwort wiederholen"} required={true}
                                                           variant={"outlined"}
                                                           type={"password"}
                                                           onChange={event => setPasswordRetype(event.target.value)}
                                                           className={classes.fullWidth}
                                                           error={passwordError}
                                                           helperText={passwordError && 'Passwörter stimmen nicht überein'}
                                                />
                                            </Grid>
                                            <Grid container item xs={12} justify={"center"}>
                                                <Button type={"submit"} variant={"contained"} color={"primary"}
                                                        disabled={passwordError || !password || password.length < 8}>
                                                    Benutzer erstellen
                                                </Button>
                                            </Grid>
                                        </>
                                    )

                                    }
                                    {editUserId &&
                                    <Grid container item xs={12} justify={"center"}>
                                        <Button type={"submit"} variant={"contained"} color={"primary"}>
                                            Speichern
                                        </Button>
                                    </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    )

}

export default CreateUser
