import React, {useContext, useEffect, useState} from 'react'
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper} from "@material-ui/core";
import {Add, Delete, Edit, VpnKey} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {userContext} from "../login/userContext";
import userService from "../service/userService";
import IconButton from "@material-ui/core/IconButton";
import ChangePasswordDialog from "./changePasswordDialog";
import {Link} from "react-router-dom";
import ConfirmationDialog from "./confirmationDialog";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },

    iconCell: {
        align: 'right',
        whiteSpace: 'nowrap'
    }
});

function UserList() {
    const classes = useStyles();


    const [users, setUsers] = useState(undefined)
    const [selectedUserName, setSelectedUserName] = useState(undefined)
    const [deactivateUserName, setDeactivateUserName] = useState(undefined)

    const user = useContext(userContext)

    useEffect(() => {
        userService.fetchUsers(user.auth, (data) => {
            setUsers(data)
        })
    }, [user, deactivateUserName])


    const handleClickOpen = (userName) => {
        setSelectedUserName(userName)
    };

    const handleClose = () => {
        setSelectedUserName(undefined)
    };

    const openDeactivateUserDialog = (userName) => {
        setDeactivateUserName(userName)
    }

    const closeDeactiveUserDialog = () => {
        setDeactivateUserName(undefined)
    }

    const deactivateUser = () => {
        userService.deactivateUser(user.auth, deactivateUserName, (data) => {
            setDeactivateUserName(undefined)
        })
    }

    return (
        <Grid container direction={"row"} justify={"center"}>
            <Grid item xs={6}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Mail</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                users &&
                                users.map((row) => (
                                    <TableRow key={row.userId}>
                                        <TableCell component="th" scope="row">
                                            {row.userId}
                                        </TableCell>
                                        <TableCell>{row.userName}</TableCell>
                                        <TableCell>{row.mail}</TableCell>
                                        <TableCell className={classes.iconCell}>
                                            <IconButton to={`users/${row.userId}`} component={Link}
                                            title={"Bearbeiten"}>
                                                <Edit/>
                                            </IconButton>
                                            <IconButton onClick={() => handleClickOpen(row.userId)} title={"Passwort ändern"}><VpnKey/>
                                            </IconButton>
                                            <IconButton onClick={() => openDeactivateUserDialog(row.userId)} title={"Deaktivieren"}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <IconButton to={'/users/create'} component={Link}><Add/></IconButton>
            </Grid>
            <ChangePasswordDialog selectedUserName={selectedUserName} onClose={handleClose}/>
            <ConfirmationDialog
                title={"User deaktivieren"}
                cancelText={"Abbrechen"}
                confirmText={"Deaktivieren"}
                text={"Der ausgewählte User wird deaktiviert. Damit kann sich dieser User nicht mehr im System anmelden"}
                selectedUserName={deactivateUserName}
                onCancel={closeDeactiveUserDialog}
                onConfirm={deactivateUser}
            />
        </Grid>
    )
}

export default UserList