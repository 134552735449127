import React, {useContext, useEffect, useState} from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import userService from "../service/userService";
import {userContext} from "../login/userContext";

function ChangePasswordDialog({selectedUserName,onClose}) {

    const user = useContext(userContext)
    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [error, setError] = useState(false)

    const open = selectedUserName !== undefined

    console.log(open)

    const changePassword = () => {
        console.log(`setting new password to ${newPassword}`)

        userService.changePassword(user.auth, selectedUserName, newPassword, (response) => {

            if(response.ok){
                onClose()
            }

        })

    }

    useEffect(() => {
        if(newPassword.length===0){
            return
        }
        setError(newPassword!==repeatPassword)

    }, [newPassword, repeatPassword])

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Passwort ändern</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Neues Passwort"
                    type="password"
                    fullWidth
                    onChange={event => setNewPassword(event.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Passwort wiederholen"
                    type="password"
                    fullWidth
                    onChange={event => setRepeatPassword(event.target.value)}
                    error={error}
                    helperText={error && 'Passwörter stimmen nicht überein'}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Abbrechen
                </Button>
                <Button
                    disabled={error || newPassword.length<5}
                    onClick={changePassword}
                    color="primary">
                    Ändern
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangePasswordDialog