import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Assignment, Dashboard, People} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {Link as RouterLink} from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    title: {
        flexGrow: 1
    }
}));

function ListItemLink(props) {
    const {icon, primary, to} = props;

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <li>
            <ListItem button component={renderLink}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary}/>
            </ListItem>
        </li>
    );
}

function SideMenu({show}) {
    const classes = useStyles()

    return <Drawer variant={"persistent"} open={show} className={classes.drawer} classes={{
        paper: classes.drawerPaper,
    }}>
        <div className={classes.toolbar}/>
        <List>
            <ListItemLink to="/" primary={"Dashboard"} icon={<Dashboard/>}/>
            <ListItemLink to="/users" primary={"Benutzer"} icon={<People/>}/>
            <ListItemLink to="/contract" primary={"Vertragsinfos"} icon={<Assignment/>}/>
        </List>
    </Drawer>
}

export default SideMenu