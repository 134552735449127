import React, {useContext, useEffect, useState} from 'react'
import InfoWidget from "./infoWidget";
import Typography from "@material-ui/core/Typography";
import {userContext} from "../login/userContext";
import statisticService from "../service/statisticsService";

function UserWidget({maxUsers}) {
    const [usersCount, setUsersCount] = useState(0)
    const user = useContext(userContext)

    useEffect(() => {
        statisticService.fetchUsers(user.auth, user.user.clientId, (data) => {
            setUsersCount(data.count)
        })
    },[user])
    return (
        <InfoWidget title={'Anzahl an Benutzern'}>
            <Typography variant={"h4"} align={"center"}>
                { `${usersCount.toLocaleString()} / ${maxUsers.toLocaleString()}`}
            </Typography>

        </InfoWidget>
    )
}

export default UserWidget